import * as React from "react"
import Layout from "../../../components/Layout"
import Seo from "../../../components/Seo"


const MonstapalsPage = () => {

  return (
    <Layout>
      <Seo 
        title="Monstapals Privacy Policy"
        description="Our privacy declaration for Monstapals"
      />

    <article className="flex flex-col items-center justify-between mx-auto mb-10 rounded-md max-w-prose">
        <h1 className="w-full mb-6 text-3xl font-light text-center text-gray-600">Monstapals - Privacy Policy</h1>
        <p className="text-gray-500">Updated 22nd January 20222</p>
        <div className="p-6 mt-8 bg-white border border-gray-100 lg:p-16">
            <div className="prose text-gray-600">
                <h2 className="text-2xl font-bold">Related to the game</h2>
                <p><b>We take your privacy extremely seriously.</b></p>
                <p>We do not collect any personally identifying information and the only data we collect is anonymised and used for analytics (via <a href="http://unity3d.com">Unity</a> game engine) and improving the application. </p>
                <p>We will never sell or share your data with a third party. Monstapals does not contain any in-app purchases, advertisements, links or revenue baiting user interfaces.</p>
                <p>We will attempt to access the photo library on your device to save screenshots taken in game. If you choose not to allow access the screenshots will only be displayed in game but not saved to your device.</p>
                <p>Our commitment is towards you and your child to ensure you have fantastic entertainment experience without any distractions or worries. We encourage you to write to us if you have any concerns and we will endeavour to respond promptly.</p>
                <h2 className="text-2xl font-bold">Related to the sticker packs</h2>
                <p>Our sticker packs are designed to be fun addons to the game to share cheer around occasions like Christmas, Halloween or the game in general.</p>
                <p>These iMessage apps do not store any data, collect any information around the use or the user. There are no third party analytics engines. There is absolutely no data collected by these apps.</p>
            </div>
        </div>
    </article>
    </Layout>
 );
};
export default MonstapalsPage
